import * as React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import portrait from '../../assets/portrait.jpg'

export const Intro = () => {
  return (
    <Box my="3rem" minH={495} textAlign="center">
      <Box textAlign="center" p={4}>
        <Text as="h1" fontSize="3xl" p={5}>Full Stack Software Engineer</Text>
        <Text fontSize="lg">I enjoy writing code to solve problems and create useful tools.</Text>
        <Image src={portrait} alt="collier-devlin-portrait"
          border={0}
          borderRadius="50%"
          boxShadow="2px 4px 5px rgb(0,0,0,0.3)"
          m="35px auto 20px auto"
          w="100%"
          height="auto"
          maxW={300}
        />
      </Box>
    </Box>
  )
}