import * as React from 'react'
import { Box, Image, Text, Link } from '@chakra-ui/react'
import chakraUILogo from '../../assets/chakra-ui.svg'

export const Footer = () => {
  return (
    <Box textAlign="center" mx="auto" mt={25}>
      <Text fontSize="xs" opacity={0.6} textAlign="center">
        Made with
      </Text>
      <Box my={2} mx="auto" w={100} textAlign="center">
        <Link href="https://chakra-ui.com/" target="__blank" w="auto">
          <Image style={{
            width: '100%',
            height: 'auto',
            maxWidth: '85px',
            opacity: '0.6',
            ':hover': { opacity: '0.9', cursor: 'pointer' }
          }} src={chakraUILogo} alt="chakra-ui-logo" />
        </Link>
      </Box>
      <Text mt={45} fontSize="xs" color="#86A0A8">
        Created by me &copy; {(new Date()).getFullYear()}
      </Text>
    </Box>
  )
}