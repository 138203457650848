import React from 'react'
import { Box } from '@chakra-ui/react'
import AppBar from '../components/common/AppBar'
import '../styles/base.css'
import { Footer } from '../components/common/Footer'
import Seo from '../components/common/SEO'

const BaseLayout = ({ children, title }) => {
  return (
    <>
      <Seo title={title} />
      <Box pb={2}>
        <AppBar />
        { children }
        <Footer />
      </Box>
    </>
  )
}

export default BaseLayout
