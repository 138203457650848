import * as React from 'react'
import moment from 'moment'
import { Box, Text } from '@chakra-ui/react'
import { Container } from '../common/Container'

export const About = () => {
  const yearsOfExperience = moment().diff(moment('2014-06-23'), 'years')
  
  return (
    <Box background="green.300" minH={100} pb={100} textAlign="center">
      <Container py={10}>
        <Text as="h5" fontSize="2xl" color="white">Hi, I'm Collier. Great to meet you!</Text>
        <Text fontSize="md" color="white" mx="auto" my={50} maxW={650}>
          Since I began my career {yearsOfExperience} years ago, I've quickly become an expert with front-end development, being a team leader focusing on UI design and React / Vue JS. Along the way, I have learned numerous back-end languages to help create and integrate APIs with my interfaces.
        </Text>
      </Container>
    </Box>
  )
}