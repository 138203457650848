import * as React from 'react'
import BaseLayout from '../layout/base'
import { Intro } from '../components/section/Intro'
import { About } from '../components/section/About'
import { Skills } from '../components/section/Skills'

const Index = () => (
  <BaseLayout title="Portfolio | Collier Devlin">
    <Intro />
    <About />
    <Skills />
  </BaseLayout>
)

export default Index
