import * as React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import codeIcon from '../../assets/code.svg'
import frontEndCodeIcon from '../../assets/frontend-code.svg'

const SkillListing = ({ title, list }) => {
  return (
    <Box my={30}>
      <Text fontWeight="bold" color="green.500">{title}:</Text>
      {
        list.map(({ name }) => (
          <Text key={name} fontSize="sm" mt={5}>{name}</Text>
        ))
      }
    </Box>
  )
}

export const Skills = () => {
  const itemProps = {
    minHeight: '100',
    minWidth: 200,
    flexShrink: 0,
    textAlign: 'center'
  }

  return (
    <Box mt={-90} px={15} textAlign="center" background="transparent">
      <Box style={{
        background: 'white',
        border: '2px solid #f1f1f1',
        borderRadius: '5px',
        boxShadow: '2px 4px 5px rgb(0,0,0,0.1)',
        margin: '0 auto 50px auto',
        maxWidth: '1080px',
        width: '100%',
        minHeight: '300px',
      }}>
        <Box d="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
          <Box {...itemProps}>
            <Box px={10}>
              <Image color="green.500" style={{
                margin: '35px auto 10px auto',
                height: '100%',
                width: 'auto',
                maxHeight: '75px'
              }} src={frontEndCodeIcon} alt="frontend-code-icon-svg" />
              <Text as="h5">Front-end</Text>
              <Text fontSize="sm" mt={5}>I focus on simplicity for users and the developers reading my code.</Text>
              <SkillListing title="Frameworks" list={[
                { name: 'React JS' },
                { name: 'Vue JS' },
                { name: 'Typescript' },
                { name: 'Kotlin (Android)' }
              ]} />
              <SkillListing title="Tools" list={[
                { name: 'VS Code' },
                { name: 'Figma' },
                { name: 'Git' }
              ]} />
            </Box>
          </Box>
          <Box {...itemProps}>
            <Box px={10}>
              <Image color="green.500" style={{
                margin: '50px auto 20px auto',
                height: '100%',
                width: 'auto',
                maxHeight: '50px'
              }} src={codeIcon} alt="code-icon-svg" />
              <Text as="h5">Back-end</Text>
              <Text fontSize="sm" mt={5}>I am an advocate for microservices and enjoy writing simple, explicit endpoints.</Text>
              <SkillListing title="Languages" list={[
                { name: 'Kotlin/Java' },
                { name: 'Node JS' },
                { name: 'Golang' },
                { name: 'PHP/Laravel' },
              ]} />
              <SkillListing title="Tools" list={[
                { name: 'Intellij' },
                { name: 'Android Studio' },
                { name: 'Git' }
              ]} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}