import * as React from "react"
import { MdDehaze } from 'react-icons/md'
import logo from '../../assets/cd-logo.svg'
import resume from '../../files/2023-resume.pdf'
import { Box, Link, Image, useDisclosure,
  Text,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter
} from "@chakra-ui/react"

export const headerHeight = 85

export const MobileMenu = ({ links }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Box height={headerHeight} ml={5} display="flex" justifyContent="center" alignItems="center" color="green.300" fontWeight={600}>
        <IconButton ref={btnRef} icon={<MdDehaze />} onClick={onOpen} colorScheme="transparent" fontSize="1.5em" color="green.300" />
      </Box>
      <Drawer
        size="xs"
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pt={headerHeight}>
            { links && links.map(link => <DrawerLink key={link.title} my={5} {...link} />) }
          </DrawerBody>
          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const DesktopMenu = ({ links }) => (
  <>
    { links && links.map(link => <HeaderLink key={link.title} {...link} />) }
  </>
)

export const HeaderLink = ({ title, render: NavLink }) => (
  <NavLink>
    <Box height={headerHeight} fontSize={['1rem', '1rem', '0.9rem', '1rem']} w={[120, 120, 100, 120]} display="flex" justifyContent="center" alignItems="center" color="green.300">
      <Text color="green.500" fontSize="sm">{title}</Text>
    </Box>
  </NavLink>
)

export const DrawerLink = ({ title, render: NavLink }) => (
  <Box my={3} py={2}>
    <NavLink>
      <Text color="green.500" fontSize="sm" fontWeight={600}>
        {title}
      </Text>
    </NavLink>
  </Box>
)

const AppBar = () => {
  const links = [
    {
      title: 'Resume',
      render: ({ children }) => <Link href={resume} download>{ children }</Link>
    },
    {
      title: 'Contact',
      render: ({ children }) => <Link href="https://www.linkedin.com/in/collier-devlin-612b7854/" target="_blank">{ children }</Link>
    }
  ]

  return (
    <Box>
      <Box display="flex" maxW={1200} mx="auto" h={headerHeight} position="relative" alignItems="center">
      {
          (links || []).length > 0 && (
            <Box display={[ "flex", "flex", "none", "none" ]} minHeight={headerHeight}>
              <MobileMenu links={links} />
            </Box>
          )
        }
        <Box placeSelf="center" p={1} mt={1} mr={2} ml={2} width="100%" maxWidth={[110, 110, 125, 135]}>
          <Link to={`/`}>
            <Image src={logo} color="green.500" alt="cd-logo" w="auto" h="80%" maxH={45} />
          </Link>
        </Box>
        {
          (links || []).length > 0 && (
            <Box display={[ "none", "none", "flex", "flex" ]} minHeight={headerHeight} w="100%" maxWidth={1080} margin="0 auto" justifyContent="end">
              <DesktopMenu links={links} />
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

export default AppBar
